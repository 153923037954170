/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('companyResource', ['$resource', function ($resource) {
    return $resource('/companies/:id/:action', {id: '@id', action: '@action'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                action: 'find'
            }
        },
        searchCities: {
            url: '/companies/:id/:action/cities',
            method: 'GET',
            params: {
                action: 'find'
            }
        },
        assign: {
            method: 'PUT',
            params: {
                action: 'assign',
                id: '@id'
            }
        },
        hasConfirmedReport: {
            method: 'GET',
            url: '/companies/:id/hasConfirmedReport/:year',
            params: {
                id: '@id',
                year: '@year'
            }
        },
        hasNotConfirmedReport: {
            method: 'GET',
            url: '/companies/:id/has-not-confirmed-report',
            params: {
                id: '@id'
            }
        }
    });
}]);