users.controller('CloseCompanyController', ['$scope', 'companyService', function ($scope, companyService) {

    $scope.showCloseMessage = false;
    $scope.companyEntity = $scope.$parent.data;
    $scope.data = {};

    if ($scope.companyEntity.status == 2) {
        $scope.showCloseMessage = true;
    }

    $scope.sendRequest = sendRequest;

    $scope.reportsChecked = false;
    $scope.hasNotConfirmedReport = true;
    companyService.hasNotConfirmedReport($scope.companyEntity.id).then(function (response) {
        $scope.hasNotConfirmedReport = response.data;
        $scope.reportsChecked = true;
    });

    $scope.$on('closeCompanyEvent', function () {
        $scope.checking = true;
    });


    function sendRequest() {
        companyService.sendCloseRequest($scope.companyEntity.id, $scope.data).then(function (data) {
            $scope.companyEntity = data;
            if (data.status == 2) {
                $scope.showCloseMessage = true;
                $scope.loggedUser.company.status = data.status;
            }
        });
    }


}]);