reports.directive('newReport', ['$uibModal', 'reportsService', '$state', 'COMPANY_STATUS', function ($uibModal, reportsService, $state, COMPANY_STATUS) {
    return {
        restrict: 'E',
        scope: false,
        template: '<button class="btn btn-success btn-sm" ng-click="newReportModal()">Nowe sprawozdanie</button>',
        link: function (scope, element, attrs) {
            scope.newReportModal = function() {
                scope.reportYear = (new Date()).getFullYear() - 1;
                scope.years = reportsService.getYears();

                scope.processing = false;

                var modalCorrectionInstance = $uibModal.open({
                    templateUrl: appRoot + '/reports/directives/newReport.modal.html',
                    size: 'small',
                    animation: true,
                    scope: scope,
                    resolve: {
                        items: function () {
                            return null;
                        }
                    }
                });

                scope.save = function() {
                    scope.processing = true;
                    var formData = {
                        name: 'Sprawozdanie ' + moment().format('DD/MM/YYYY HH:mm'),
                        reportYear: scope.reportYear,
                        companyName: scope.loggedUser.company.name,
                        firstName: scope.loggedUser.firstName,
                        lastName: scope.loggedUser.surname,
                        creatorId: scope.loggedUser.id,
                        report_items: [{
                            gwp: 0
                        }]
                    }
                    reportsService.saveReport(formData).then(function (data) {
                        scope.processing = false;
                        if (scope.loggedUser.company.status == COMPANY_STATUS.waiting_for_close) {
                        	scope.loggedUser.company.status = COMPANY_STATUS.active;
                        }
                        return $state.go('reports.edit', {id: data.id});
                    });
                }
                modalCorrectionInstance.opened.then(function () {
                    scope.cancel = function() {
                        modalCorrectionInstance.close();
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            }
        }
    }
}]);